@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,100..900;1,100..900&display=swap");
@import url(./App.css);
/* @font-face {
  font-family: "Area";
  src: url("./assets/AreaRegular.otf") format("otf");
  font-weight: normal;
} */

@font-face {
  font-family: "Mont";
  src: url(./assets/Mont-Regular.otf);
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Mont";
  src: url(./assets/Mont-Bold.otf);
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  min-height: 100vh;
  background: transparent linear-gradient(180deg, #5423e7 0%, #2a1274 100%) 0%
    0% no-repeat padding-box;
  overflow-x: hidden;
  font-family: "Figtree", sans-serif;
}
.landing-wrap,
.unprecedented {
  background: transparent linear-gradient(180deg, #5423e7 0%, #2a1274 100%) 0%
    0% no-repeat padding-box;
}
.landing-page {
  min-height: 100vh;
}
.contact-us .landing-page {
  min-height: 85vh;
}
button {
  border: none;
  outline: none;
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.logo {
  height: 3.2rem;
}

.container {
  padding: 0 8rem;
}

ul {
  list-style: none;
}

header {
  padding: 1.5rem 5.5rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #5323e6;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header ul {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav-bar {
  display: flex;
  align-items: center;
  gap: 5rem;
}
.btn-light,
.btn-light:before {
  border-radius: 27px;
  height: 45px;
}

.btn-light {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 27px;
  color: #121219;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1.2rem;
  font-family: "Figtree", sans-serif;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.ft {
  background-color: #29116e;
  border-radius: 30px;
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}

.ft .main {
  height: 27rem;
  position: relative;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.ft .line {
  width: 100%;
  background-color: #fafafa64;
  height: 2px;
  margin: 0.8rem 0;
}

.case-study {
  max-width: 80%;
  padding: 0 2rem;
  padding-bottom: 3rem;
}
.case-study p {
  color: #ffffff;
  opacity: 0.8;
  font-size: 1.4rem;
  line-height: 2.2rem;
  margin-top: 1rem;
  max-width: 90%;
}
.cursor {
  cursor: pointer;
}
.nav-item {
  color: #ffffff;
  font-size: 1.2rem;
  padding: 5px 0;
  cursor: pointer;
  /* transition: all ease-in 1s; */
  position: relative;
}

.login:hover:before {
  content: "";
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: 2.5px;
  bottom: 0;
}

.hero-title {
  color: #ffffff;
  font-size: 5rem;
  max-width: 46%;
  font-weight: 600;
  font-family: "Mont", sans-serif;
  line-height: 6rem;
}

.contact-us .hero-title,
.contact-us .hero-subtitle {
  max-width: 70%;
}
.hero-left {
  position: relative;
  top: 4rem;
}

.hero-subtitle {
  color: #ffffff;
  font-size: 1.6rem;
  max-width: 50%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  line-height: 2.4rem;
  opacity: 0.8;
  font-weight: 300;
}

.hero-section {
  position: relative;
}

.hero-image {
  position: absolute;
  right: 0rem;
  top: 0rem;
}

.hero-image img {
  height: 44rem;
}

/* Trusted */
.trusted {
  min-height: 30vh;
  display: flex;
  gap: 3rem;
  align-items: center;
  margin-top: 4rem;
}
.trusted p {
  color: #ffffff;
  font-size: 1.2rem;
  max-width: 20rem;
  margin-right: 4rem;
  opacity: 0.52;
  font-weight: 400;
  line-height: 2rem;
}

.trusted ul {
  display: flex;
  align-items: center;
  gap: 5rem;
}

.trusted ul li img {
  height: 2.5rem;
}

/* why-br */
.why-br {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.why-br span,
.head-sec span {
  font-size: 1rem;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 300;
  opacity: 0.52;
  letter-spacing: 2.17px;
}
.head-sec span {
  color: #5423e7;
  opacity: 1;
  margin-bottom: 0.7rem;
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.btn-light:hover:before {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.btn-light:before {
  background: #28116e21;
  content: "";
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.6s, opacity 0.8s;
  width: 100%;
}

.head-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5rem 0;
}

.head-sec h4 {
  font-size: 2.5rem;
  max-width: 65%;
  text-align: center;
  font-weight: 600;
  line-height: 3.2rem;
  font-family: "Mont", sans-serif;
}
.why-br ul li label,
.why-br p.ans {
  font-size: 2.7rem;
  color: #ffffff;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 4rem;
  max-width: 95%;
  line-height: 3.7rem;
}

.why-br ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.why-br ul li p {
  color: #ffffff;
  font-weight: 400;
  max-width: 70%;
  margin-top: 0.5rem;
  opacity: 0.7;
  font-size: 1.2rem;
  line-height: 1.8rem;
}

@media (min-width: 1024px) and (max-width: 1279px) {
  html {
    font-size: 10.5px;
  }
}
@media (min-width: 1280px) and (max-width: 1365px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 1366px) and (max-width: 1439px) {
  html {
    font-size: 13px;
  }
}
@media (min-width: 1440px) and (max-width: 1599px) {
  html {
    font-size: 13.5px;
  }
}

@media (min-width: 1600px) and (max-width: 1999px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 1700px) and (max-width: 1919px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 1920px) {
  html {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  header {
    padding: 2rem;
  }
  html {
    font-size: 10px;
  }
  .container {
    padding: 0rem 2.5rem;
  }
  .products .cards,
  .trusted,
  .spend .cards,
  .ft,
  .unprecedented .cards {
    flex-direction: column;
  }

  .hero-image {
    position: relative;
    top: 7rem;
    right: -2.3rem;
    margin-bottom: 10rem;
  }
  .hero-title {
    font-size: 3.8rem;
  }
  .hero-title,
  .hero-subtitle {
    max-width: 100%;
    text-align: center;
  }
  .hero-image img {
    width: 100%;
    height: 27rem;
    margin-top: 3rem;
  }

  .g-started {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .trusted p {
    max-width: 100%;
    line-height: 2rem;
  }

  .rfm-marquee-container {
    max-width: 95%;
  }

  .trusted ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  .why-br ul {
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
  }
  .why-br p.ans {
    line-height: 3rem;
    font-size: 2rem;
  }
  .why-br ul li label {
    font-size: 3rem;
  }
  .why-br ul li p {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }

  .why-br {
    padding-bottom: 5rem;
  }

  .head-sec h4,
  .head-sec span {
    max-width: 100%;
    text-align: left;
  }

  .head-sec {
    align-items: flex-start;
    gap: 1rem;
  }
  .head-sec p {
    text-align: left;
  }

  .contact-us .hero-title,
  .contact-us .hero-subtitle {
    max-width: 100%;
  }

  .contact-us .hero-left {
    margin-bottom: 5rem;
  }

  .case-study {
    max-width: 100%;
    padding-bottom: 3rem;
  }
  .ft .main {
    width: 100%;
    height: 25rem;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px;
    margin-bottom: 2rem;
  }
}
