.products,
.spend {
  min-height: 100vh;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.spend {
  display: flex;
  align-items: center;
}
.cards div:hover {
  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.12);
  transition: all 0.2s ease-in-out;
}
.unprecedented .cards div,
.products .cards div {
  background: transparent linear-gradient(180deg, #f6f4ff 0%, #e3d9ff 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  padding: 2rem;
  border-radius: 20px;
  position: relative;
}
.unprecedented .cards {
  display: flex;
  justify-content: center;
}
.unprecedented .cards div {
  background: #ffffff1b;
  display: flex;
  align-items: flex-start;
  width: 45%;
}
.unprecedented .cards div img {
  height: 5rem;
  display: block;
  margin-right: 1rem;
}
.products .cards div img {
  height: 17rem;
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 1.5rem 0;
  display: block;
  margin-top: 3rem;
}

.products .cards div.bottom img {
  height: 20.5rem;
  position: absolute;
  margin-bottom: 0;
}
.products .cards,
.spend .cards,
.unprecedented .cards {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.unprecedented .cards label,
.spend .cards label,
.products .cards label {
  color: #121219;
  font-size: 2rem;
  font-weight: 600;
  font-family: "Mont", sans-serif;
}

.unprecedented .cards p,
.spend .cards p,
.products .cards p {
  color: #000000;
  font-size: 1.4rem;
  max-width: 90%;
  margin: 1rem 0;
  line-height: 2.1rem;
}
.your-money {
  display: flex;
  align-items: center;
  min-height: 100vh;
}
.your-money .head-sec span {
  color: #ffffff;
  margin-bottom: 0.6rem;
}

.unprecedented .cards p,
.unprecedented .cards label {
  color: #ffffff;
}

.head-sec p {
  max-width: 80%;
  text-align: center;
  margin-top: 0.7rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

.build-to-scale,
.dedicated {
  min-height: 100vh;
  background: #f9f8ff 0% 0% no-repeat padding-box;
}

.build-to-scale aside,
.dedicated aside {
  width: 33.3%;
}
.dedicated aside img {
  height: 12rem !important;
}
aside label {
  margin-top: 2rem;
  display: inline-block;
}
aside label,
aside p {
  margin-left: 1rem !important;
}

.unprecedented .head-sec p,
.unprecedented .head-sec h4 {
  color: #ffffff;
}
.unprecedented .head-sec p {
  opacity: 0.6;
}
.unprecedented img.main {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 2rem 0;
}

.pb-5,
.spend {
  padding-bottom: 5rem !important;
}

.unprecedented {
  padding-bottom: 4rem !important;
}

.left-sec {
  display: flex;
  align-items: center;
  margin: 7rem 0;
}

.left-sec div:first-child {
  width: 60%;
}

.left-sec div:last-child {
  width: 40%;
}
.left-sec .head-sec {
  align-items: flex-start;
}
.left-sec .head-sec p,
.left-sec .head-sec h4 {
  text-align: left;
  max-width: 80%;
}
.left-sec .head-sec h4 {
  font-weight: 500;
}
.mt-2 {
  margin-top: 2rem;
}

.left-sec img {
  position: relative;
  left: 40%;
  transform: translateX(-50%);
}

.spend .cards aside img {
  height: 20.5rem;
}

.footer-sec .head-sec span {
  text-align: left;
  color: #f6c454;
  margin-bottom: 1rem;
}

.footer-sec .flex-between {
  min-height: 45vh;
}
.footer-sec .head-sec h4 {
  text-align: left;
  color: #ffffff;
  max-width: 70%;
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 500;
}

.footer-sec .head-sec {
  align-items: flex-start;
}

.footer-sec .btn-light {
  min-width: 18rem;
  justify-content: center;
}

.footer-sec address {
  color: #ffffff73;
}

footer {
  display: flex;
  align-items: flex-start;
  gap: 7rem;
}

.footer-heading {
  color: #ffffff;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.footer-section ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-section ul li {
  color: #ffffff73;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff73;
  padding: 5rem 0;
}

.footer-links {
  display: flex;
  gap: 1rem;
}

.contact-us form {
  background: #ffffff30;
  padding: 3rem;
  border-radius: 10px;
  width: 100%;
  margin-top: 3rem;
}

.contact-us form div {
  display: flex;
  justify-content: center;
}
.contact-us form input {
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  height: 3.5rem;
  width: 85%;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  color: white;
  padding: 10px;
}

.contact-us form input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.contact-us form input:focus {
  outline: none;
}

.contact-us form label {
  position: relative;
}
.contact-us form input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  z-index: 10;
}

.contact-us form label {
  display: flex;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.6);
  line-height: 1.5rem;
  font-weight: 400;
  align-items: flex-start;
  font-size: 0.9rem;
  width: 90%;
  margin-left: 1.8em;
}
.contact-us form label p:first-child {
  margin-top: 5px;
}

/* Create a custom checkbox */
.checkmark {
  width: 1.2rem;
  height: 1.2rem;
  display: block;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

/* Show the checkmark when checked */
input:checked ~ .checkmark:after {
  display: inline-block;
}

input:checked ~ .checkmark {
  background: transparent;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkmark:after {
  left: 7px;
  top: 8.5px;
  width: 4px;
  height: 8px;
  border: solid rgba(255, 255, 255, 0.4);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.contact-us footer {
  margin-top: 5rem;
}

.contact-us .hero-left {
  width: 80%;
}

.contact-us .hero-section {
  margin-top: 1rem;
}
.mobile {
  display: none;
}
@media (max-width: 600px) {
  .footer-sec .head-sec h4 {
    max-width: 100%;
  }
  .products .cards div.bottom img {
    height: 10rem;
    position: relative;
    bottom: -2rem;
  }

  .products .cards div img {
    height: 13rem;
  }

  .build-to-scale aside,
  .dedicated aside {
    width: 100%;
  }

  .unprecedented img.main {
    height: 15rem !important;
  }
  .unprecedented .cards div img {
    height: 5rem;
  }
  .unprecedented .cards div {
    width: 100%;
    padding: 3rem 2rem;
  }

  footer {
    margin-top: 4rem;
  }
  .hero-section .flex-between,
  .footer-sec .flex-between,
  footer {
    flex-direction: column;
  }
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }
}
